var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"label":"Рег. номер","items":_vm.regNumbers,"hide-details":"","outlined":"","prepend-inner-icon":_vm.filterData.regNumber ? 'mdi-close' : 'mdi-credit-card-chip-outline'},on:{"change":_vm.load,"click:prepend-inner":function($event){_vm.filterData.regNumber = ''; _vm.load()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"pl-1"},[_vm._v("Рег. номер")])]},proxy:true}]),model:{value:(_vm.filterData.regNumber),callback:function ($$v) {_vm.$set(_vm.filterData, "regNumber", $$v)},expression:"filterData.regNumber"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Статус действия","items":[
                     {text: '', value: null},
                     {text: 'Не найдена', value: 'notFound'},
                     {text: 'Просрочена', value: 'expired'},
                     {text: 'Заканчивается в течении 20 дней', value: 'endsTwentyDays'},
                     {text: 'Активная', value: 'active'},

                ],"hide-details":"","outlined":"","prepend-inner-icon":_vm.filterData.status ? 'mdi-close' : 'mdi-check-circle-outline'},on:{"change":_vm.load,"click:prepend-inner":function($event){_vm.filterData.status = ''; _vm.load()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"pl-1"},[_vm._v("Статус действия")])]},proxy:true}]),model:{value:(_vm.filterData.status),callback:function ($$v) {_vm.$set(_vm.filterData, "status", $$v)},expression:"filterData.status"}})],1),_c('v-col',{attrs:{"align-self":"center","align":"right"}},[_c('dcs-export-excel')],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',{},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"headers":[
  {text: ''},
  {text: 'Гос. номер', value: 'regNumber'},
  {text: 'VIN',  value: 'vin'},
  {text: '', sortable: false},
  {text: 'Номер ЕАИСТО', value: 'dcNumber'},
  {text: 'Дата диагностики', value: 'dcIssueDate'},
  {text: 'Действует до', value: 'dcExpiryDate'},
  {text: 'Пробег ТС зафиксированый при ТО', value: 'dcOdometer'},
  {text: 'Оператор', value: 'dcOperatorStatus'},
    ],"items":_vm.items,"disable-pagination":"","disable-filtering":"","hide-default-footer":"","must-sort":true},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"pa-10 text-body-1"},[_vm._v(" Данные отсутствуют ")])]},proxy:true},{key:"item",fn:function({ item }){return [_c('tr',{ref:item.dcNumber},[_c('td',{staticStyle:{"width":"10px"}},[(item.vin.length === 17)?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.openMore(item.vin)}}},[_c('v-icon',[_vm._v(" mdi-page-next-outline")])],1):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(item.regNumber)+" "),_c('br'),_c('small',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.brand)+" "+_vm._s(item.model)+" ")])]),_c('td',{staticStyle:{"width":"230px"}},[_vm._v(" "+_vm._s(item.vin)+" ")]),_c('td',{staticStyle:{"width":"1px"}},[_c('diagnostic-card-status-chip',{attrs:{"expiry-date":item.dcExpiryDate}})],1),_c('td',[_vm._v(" "+_vm._s(item.dcNumber)+" ")]),_c('td',{staticStyle:{"width":"125px"}},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dcIssueDate,'DD.MM.YYYY'))+" ")]),_c('td',{staticStyle:{"width":"125px"}},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dcExpiryDate,'DD.MM.YYYY'))+" ")]),_c('td',{staticStyle:{"width":"25px"}},[_vm._v(" "+_vm._s(_vm._f("odometerFormat")(item.dcOdometer,item.dcOdometer))+" ")]),_c('td',{staticStyle:{"width":"50px"}},[_c('diagnostic-card-operator-icon',{attrs:{"view":item.dcNumber !== null,"status":item.dcOperatorStatus}})],1)])]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }