
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class DiagnosticCardOperatorIcon extends Vue {

  @Prop()
  view!: string;

  @Prop()
  status!: string;

}
