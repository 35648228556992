
import {Component, Vue} from 'vue-property-decorator';
import AppTopBar from '@/components/UI/AppTopBar.vue';
import DcsTable from '@/components/dcs/DcsTable.vue';
import MenuTop from '@/components/MenuTop.vue';
import AppStore from '@/components/AppStore';
import Http from '@/Http';
import DiagnosticCardOperatorIcon from '@/components/dcs/DiagnosticCardOperatorIcon.vue';
import DiagnosticCard from '@/components/models/DiagnosticCard';

@Component({
  components: {DiagnosticCardOperatorIcon, MenuTop, DcsTable, AppTopBar}
})
export default class DcsMoreDialog extends Vue {

  loading = false;
  view = false;
  vin = '';

  items: DiagnosticCard[] = [];

  mounted() {
    AppStore.onOpenDiagnosticCardByVinDialog((vin: string) => {
      this.items = [];
      this.view = true;
      this.vin = vin;
      this.get();
    });
  }

  get() {

    this.loading = true;
    Http.post('/v2/diagnosticCards', {vin: this.vin})
        .then((response: any) => {
          this.items = response;
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((HttpError: any) => {
          Http.systemError(HttpError.error);
        });
  }
}

