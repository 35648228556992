var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" История диагностических карт "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.view = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.vin)+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":[
  {text: 'Гос. номер', value: 'regNumber'},
  {text: 'Номер', value: 'cardNumber'},
  {text: 'Дата диагностики', value: 'issueDate'},
  {text: 'Действует до', value: 'expiryDate'},
  {text: 'Пробег', value: 'odometerValue'},
  {text: 'Оператор', value: 'operatorStatus'},
    ],"items":_vm.items,"disable-pagination":"","disable-filtering":"","hide-default-footer":"","must-sort":true},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"pa-10 text-body-1"},[_vm._v(" Данные отсутствуют ")])]},proxy:true},{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.regNumber)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.cardNumber)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.issueDate,'DD.MM.YYYY'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.expiryDate,'DD.MM.YYYY'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("odometerFormat")(item.odometerValue,item.dcOdometer))+" ")]),_c('td',[_c('diagnostic-card-operator-icon',{attrs:{"view":item !== null,"status":item.operatorStatus}})],1)])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }